<template>
	<table>
		<tr>
			<td :colspan="treeData.children ? treeData.children.length * 2 : 1" 
				:class="{ parentLevel: treeData.children, extend: treeData.children && treeData.children.length > 0 && treeData.extend }">
				<div :class="{ node: true }">
					<div class="box-tbf" style="padding: 0">
						<div class="placeholder-loader" style="height: 88px; width: 180px; margin-right: 15px; border-radius: 10px"></div>
					</div>
				</div>

				<div class="extend-handle-tbf" :class="{open: !parentOpen}" v-if="treeData.children && treeData.children.length > 0"></div>
			</td>
		</tr>
		<tr v-if="treeData.children && treeData.extend" class="children-row-wrapper">
			<td v-for="(children, index) in treeData.children"
			:key="index"
			colspan="2"
			class="childLevel"
			>
			<TreeChartLoader :json="children" :zoomPage="zoomPage" />
		</td>
	</tr>
</table>
</template>

<script>

export default {
	data() {
		return {
			treeData: {},
			parentOpen: false,
		};
	},
  	props: {
  		json: Object,
		zoomPage: Number
  	},
  	name: "TreeChartLoader",
	components: {

	},
	watch: {
		json: {
			handler: function(Props) {
				let extendKey = function(jsonData) {
					var jsonExtend = [];

					jsonData.extend = true
					jsonExtend.push({ id: jsonData.id, extend: jsonData.extend })

					return jsonData;
				};
				if(Props) {
					if(typeof Props === 'object'){
						this.treeData = extendKey(Props);
					}
				}
			},
			immediate: true,
		}
	},
	mounted(){
		this.$root.$on('toggleExtendAll', (valueExtend) => {
			this.treeData = extendKey2(this.treeData, valueExtend);
		})

		function extendKey2(jsonData, extendValue) {
			var jsonExtend = [];

			jsonData.extend = extendValue
			if(jsonExtend.find(el => el.id == jsonData.id)){
				jsonExtend.find(el => el.id == jsonData.id).extend = jsonData.extend
			}else{
				jsonExtend.push({ id: jsonData.id, extend: jsonData.extend })
			}

			return jsonData;
		};
	},
  	methods: {
  	}
};
</script>
