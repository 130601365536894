<template>
<div class="tree-page opacity-page show">
    <div class="header-tree">
        <div class="tree-actions">
            <div class="left-part">
                <div class="dropdown settings-org">
                    <div class="placeholder-loader" style="height: 40px; width: 40px;"></div>
                </div>
            </div>

            <div class="center-part">
                <div class="text" v-if="$resize && $mq.above(600)">
                    <div class="placeholder-loader" style="height: 20px; width: 140px;"></div>
                </div>
            </div>

            <div class="right-part">
                <div class="placeholder-loader" style="height: 40px; width: 80px;"></div>
                <div class="placeholder-loader" style="height: 40px; width: 40px; margin-left:10px"></div>
            </div>
        </div>
        <div class="scrollable-chart">
            <div class="center-box" id="divToImage">
                <loader-tree-chart class="organigram-tbf loader" :json="Object.keys(companyData).length ? companyData : {}" :zoomPage="zoomPage" :style="'transform: scale(' + zoomPage + ');' "/>
            </div>
        </div>
    </div>
</div>
</template>

<script type="text/javascript">
	import LoaderTreeChart from '../PagesLoaders/TreeChartLoader'

	export default {
		data() {
			return {
				companyData: {"id":1,"children":[{"id":1},{"id":2}]},
      			zoomPage: 1
			};
		},
		components: {
			LoaderTreeChart
		},
        computed: {
        	
        },
		async mounted() {

		},
		methods: {
			centerTree(){
				var calcWidth = $('.scrollable-chart .center-box .parentLevel .node').position().left - ($(window).width() / 2) + (($('.scrollable-chart .center-box .parentLevel .node').width() / 2))

				$('.scrollable-chart').animate({
					scrollTop: $('.scrollable-chart').offset().top - $(window).height()/2,
					scrollLeft: calcWidth
				}, 300);

			},
		}
	};
</script>